
import { Component, Vue } from "vue-property-decorator";
import { Action, Mutation, Getter } from "vuex-class";
import {
  IArrangementMethod,
  IArrangementMethodCreateRequest,
  IArrangementMethodRequest,
  IArrangementMethodUpdateRequest
} from "@/types/arrangement_method";
import { ArrangementMethodRequest } from "@/models/arrangement_method";

@Component({})
export default class extends Vue {
  //arrangement_method
  @Action("arrangement_method/adminGet")
  public getArrangementMethod!: (arrangement_method_id: number) => void;

  @Action("arrangement_method/adminCreate")
  public create!: (params: IArrangementMethodCreateRequest) => boolean;

  @Action("arrangement_method/adminUpdate")
  public update!: (data: {
    arrangement_method_id: number;
    params: IArrangementMethodUpdateRequest;
  }) => boolean;

  @Action("arrangement_method/adminDelete")
  public deleteArrangementMethod!: (arrangement_method_id: number) => boolean;

  @Getter("arrangement_method/single")
  public arrangement_method!: IArrangementMethod;

  @Mutation("arrangement_method/clear")
  public clear!: () => void;

  //パラメータ定義
  public params: IArrangementMethodRequest = new ArrangementMethodRequest();

  //変数定義
  public arrangement_method_id = 0;
  public submit_dialog = false;
  public destroy_dialog = false;
  public valid = true;
  public lazy = false;

  //ルール設定
  public rules = {
    method: [(v: string) => !!v || "手配方法は必須です"]
  };

  //--------
  // コンポーネント作成時実行
  public async created() {
    this.arrangement_method_id = Number(
      this.$route.params.arrangement_method_id
    );
    this.clear();

    // 編集時
    if (this.arrangement_method_id) {
      await this.getArrangementMethod(this.arrangement_method_id);

      this.setDefault();
    }

    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }

  //--------
  // 更新時デフォルト値をセットする
  private setDefault() {
    this.params.createFromArrangementMethod(this.arrangement_method);
  }

  // 登録確認画面
  public submitConfirm() {
    this.submit_dialog = true;
  }

  //--------
  // 確定ボタンが押された際の挙動
  // 更新時と作成時で問合せ先が違う
  public async submit() {
    this.submit_dialog = false;
    let result = false;
    if (this.arrangement_method_id) {
      result = await this.update({
        arrangement_method_id: this.arrangement_method_id,
        params: this.params
      });
    } else {
      result = await this.create(this.params);
    }
    if (result) {
      this.$router.go(-1);
    }
  }

  //--------
  // 削除確認画面
  public destroyConfirm() {
    this.submit_dialog = false;
    this.destroy_dialog = true;
  }

  //--------
  // 削除実行
  public async destroy() {
    this.destroy_dialog = false;
    if (await this.deleteArrangementMethod(this.arrangement_method_id)) {
      this.$router.push(`/admin/arrangement_method/list`);
    }
  }
}
